import { makeApiV2Client } from '../autoServiceApiV2/client/makeApiV2Client';

export class DdmServiceV2 {
  constructor() {
    this.client = makeApiV2Client();
  }

  getOffers(customParams = []) {
    const endpoint = this._getChannelEndpoint('/offers');

    return this.client.fetch(endpoint, customParams);
  }

  _getChannelEndpoint(suffixEndpoint) {
    const channelId = document.querySelector(
      "meta[name='auto_api_channel']",
    ).content;

    return `/${channelId}${suffixEndpoint}`;
  }
}

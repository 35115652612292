import { Component } from 'preact';
import PropTypes from 'prop-types';
import Scrollbars from '../../../Scrollbars';

class DropdownFilter extends Component {
  render() {
    return (
      <div className="dropdown-filter search-filter-simple__drop search-filter-simple__item search-filter-simple__drop--active">
        <div className="card-collapse-deep search-filter-simple__drop-item">
          <div className="card-collapse-deep__header">Filtrar por:</div>

          <ul className="list list--border-bottom">
            <li>
              <strong className="dropdown-filter__list-title">Categoria</strong>
              <Scrollbars autoHeight>
                <ul className="list list--no-style">
                  {this.props.filterCategories.map(category => (
                    <li>
                      <div className="custom-check custom-check--checkbox justify-content-start">
                        <label htmlFor={`category--${category}`}>
                          <input
                            type="checkbox"
                            value={category}
                            checked={this.props.selectedCategories.includes(
                              category,
                            )}
                            onChange={() =>
                              this.props.onChangeCategories(category)
                            }
                            id={`category--${category}`}
                          />
                          <div className="custom-check__check" />
                          {category}
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
              </Scrollbars>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

DropdownFilter.defaultProps = {
  filterCategories: [],
  onChangeCategories() {},
};

DropdownFilter.propTypes = {
  selectedCategories: PropTypes.arrayOf.isRequired,
  filterCategories: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChangeCategories: PropTypes.func,
};

export default DropdownFilter;

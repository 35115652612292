import { DDMAdapter } from '../ddm_service/adapters';
import { AtdOffersAdapter } from '../ddm_service/adapters/AtdOffersAdapter';
import { getFetchServicesList, sanitizeList } from '../ddm_service/utils';
import { makeDDMOffersListSyncService } from '../ddm_service/factories/ddmOffersListSyncServiceFactory';

export function makeAllDdmOffersListSyncService() {
  const ddmOffersListSyncService = makeDDMOffersListSyncService();

  const servicesAdaptersKeys = {
    autoServiceApi: AtdOffersAdapter,
    hubService: DDMAdapter,
    serviceFca: DDMAdapter,
  };

  const fetchServicesList = getFetchServicesList(servicesAdaptersKeys);
  const filteredFetchServicesList = sanitizeList(fetchServicesList);

  return () => {
    ddmOffersListSyncService.execute(filteredFetchServicesList);
  };
}

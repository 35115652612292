import { Component } from 'preact';
import PropTypes from 'prop-types';
import ListOffer from './List';
import ItemPlaceholder from './ItemPlaceholder';

class OfferDDMListApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: this.props.items,
      showLoadMore: false,
      loadingMore: false,
      loading: true,
    };

    window.store.DDMOffersFilter.watch(state => {
      this.setState({
        items: state.list,
        loading: state.loading,
        loadingMore: state.loadingMore,
        showLoadMore: state.page < state.totalPages,
      });
    });
  }

  componentDidMount() {
    window.matchHeight.match('.list-offers__list');
  }

  componentDidUpdate() {
    window.matchHeight.match('.list-offers__list');
  }

  render() {
    const { items, loading } = this.state;

    if (loading) {
      return (
        <div className="list-offers__list">
          <div className="row">
            <div className="col-md-6 col-lg-3 list-offers__col-card">
              <ItemPlaceholder />
            </div>
            <div className="col-md-6 col-lg-3 list-offers__col-card">
              <ItemPlaceholder />
            </div>
            <div className="col-md-6 col-lg-3 list-offers__col-card">
              <ItemPlaceholder />
            </div>
            <div className="col-md-6 col-lg-3 list-offers__col-card">
              <ItemPlaceholder />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-3 list-offers__col-card">
              <ItemPlaceholder />
            </div>
            <div className="col-md-6 col-lg-3 list-offers__col-card">
              <ItemPlaceholder />
            </div>
            <div className="col-md-6 col-lg-3 list-offers__col-card">
              <ItemPlaceholder />
            </div>
            <div className="col-md-6 col-lg-3 list-offers__col-card">
              <ItemPlaceholder />
            </div>
          </div>
        </div>
      );
    }

    if (items.length > 0) {
      return (
        <ListOffer
          items={items}
          loading={loading}
          loadingMore={this.state.loadingMore}
          showLoadMore={this.state.showLoadMore}
          channelUrl={this.props.channelUrl}
          brand={this.props.brand}
        />
      );
    }

    return null;
  }
}

OfferDDMListApp.defaultProps = {
  items: [],
  channelUrl: '/',
  brand: 'citroen',
};

OfferDDMListApp.propTypes = {
  items: PropTypes.arrayOf(),
  channelUrl: PropTypes.string,
  brand: PropTypes.string,
};

export default OfferDDMListApp;

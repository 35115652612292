import { Component } from 'preact';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class ButtonOrder extends Component {
  render() {
    const { isOpen, onClick } = this.props;

    return (
      <button
        className={classNames(
          'btn button button--large button--no-upper search--button search--button-order',
          {
            'search--button--active': isOpen,
          },
        )}
        type="button"
        onClick={e => onClick(e)}
      >
        <span>
          <span>
            {isOpen ? 'Fechar' : 'Ordenar'}
            <i className="icon icon-chevron" />
          </span>
        </span>
      </button>
    );
  }
}

ButtonOrder.defaultProps = {
  onClick: () => {},
  isOpen: false,
};

ButtonOrder.propTypes = {
  onClick() {},
  isOpen: PropTypes.bool,
};

export default ButtonOrder;

import { h } from 'preact';
// eslint-disable-next-line react/no-deprecated
import { render } from 'react';

import ChooseVehicleStep from '../auto/components/FinancingSimulator/steps/ChooseVehicle';
import PersonalInformationStep from '../auto/components/FinancingSimulator/steps/PersonalInformation';
import UsedModelEvaluationStep from '../auto/components/FinancingSimulator/steps/UsedModelEvaluation';
import FinancingSimulationStep from '../auto/components/FinancingSimulator/steps/FinancingSimulation';
import FinancingSummaryStep from '../auto/components/FinancingSimulator/steps/FinancingSummary';
import FinancingCTABox from '../auto/components/FinancingSimulator/FinancingCTABox';
import ShowcaseChoiceVersion from '../auto/components/ShowcaseChoiceVersion/ShowcaseChoiceVersion';
import FcaCard from '../auto/components/Cards/Fca';
import OfferDDMListApp from '../auto/components/Listings/OffersDDMV2/ListApp';
import OfferDDMFilter from '../auto/components/Listings/OffersDDMV2/Filter/SimpleFilterV2';
import OfferDDMResultSearchSimple from '../auto/components/Listings/OffersDDM/Filter/Result/ResultSearchSimple';
import OfferDDMConversionFormNoResult from '../auto/components/Listings/OffersDDM/Filter/Result/ConversionFormNoResult';
import VersionComparison from '../auto/components/VersionComparison/VersionComparison';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  require('preact/debug');
}

(function () {
  window.h = h;
  window.render = render;

  /**
   * Para ser usado quando se deseja sobrescrever conteúdo do container de rendeziração.
   * Seu uso é semelhante a função render
   */
  window.hydrate = (vnode, parent) =>
    render(vnode, parent, parent.firstElementChild);

  // Adicione aqui chaves:valor com os componentes que serão atribuídos ao escopo
  // global. Não passe eles pelo hyperscript (h) aqui pois a página que os usa
  // é quem deve compilá-lo com os pros.
  const components = {
    ChooseVehicleStep,
    PersonalInformationStep,
    UsedModelEvaluationStep,
    FinancingSimulationStep,
    FinancingSummaryStep,
    FinancingCTABox,
    ShowcaseChoiceVersion,
    FcaCard,
    OfferDDMListApp,
    OfferDDMFilter,
    OfferDDMResultSearchSimple,
    OfferDDMConversionFormNoResult,
    VersionComparison,
  };

  Object.keys(components).forEach(c => (window[c] = components[c]));
})();

import { Component } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CardCitroen from '../../Cards/NewOffersCitroen/index';
import CardPsa from '../../Cards/Psa/index';
import CardFca from '../../Cards/Fca/index';
import CardRAM from '../../Cards/NewsOffersRam/index';
import ItemPlaceholder from './ItemPlaceholder';
import ButtonLoadMore from './ButtonLoadMore';
import { cardDDMOffersLink } from '../../../helpers';

class OfferDDMList extends Component {
  getCard(brand = 'citroen') {
    const brandLowercased = brand.toLocaleLowerCase();

    const cards = {
      citroen: CardCitroen,
      peugeot: CardPsa,
      fiat: CardFca,
      jeep: CardFca,
      ram: CardRAM,
    };

    return cards[brandLowercased];
  }

  getCardSubtitle(offer) {
    const { brand, model } = offer;
    const brandSanitized = brand.toLocaleLowerCase().replace('ë', 'e');

    const cards = {
      citroen: model,
      peugeot: brand,
      fiat: model,
      jeep: model,
      ram: model,
    };

    return cards[brandSanitized];
  }

  getGridLargeColumns(brand = 'citroen') {
    const brandLowercased = brand.toLocaleLowerCase();

    const columns = {
      citroen: 'col-lg-3',
      peugeot: 'col-lg-3',
      fiat: 'col-lg-4',
      jeep: 'col-lg-4',
      ram: 'col-lg-4',
    };

    return columns[brandLowercased];
  }

  sealListLowercased(sealList) {
    return sealList.map(seal => seal.toLocaleLowerCase());
  }

  showBadge(basedType) {
    return basedType.includes('Hub') || basedType.includes('Marketplace');
  }

  render() {
    const {
      items,
      loading,
      showLoadMore,
      onClickButtonLoadMore,
      loadingMore,
      channelUrl,
      brand,
    } = this.props;

    const Card = this.getCard(brand);

    return (
      <div className="list-offers__list">
        <div className="row list-offers__list-row">
          {items.map(offer => (
            <div
              className={classNames(
                'col-md-6 list-offers__col-card',
                this.getGridLargeColumns(brand),
              )}
            >
              {loading ? (
                <ItemPlaceholder />
              ) : (
                <Card
                  title={offer.name}
                  subtitle={this.getCardSubtitle(offer)}
                  image={offer.thumbs.middle_image}
                  sealList={this.sealListLowercased(offer.seal_list)}
                  link={cardDDMOffersLink(
                    offer.base_type,
                    offer.slug,
                    channelUrl,
                  )}
                  offer={offer.item_offer}
                  lazy
                  showBadge={this.showBadge(offer.base_type)}
                  badge={offer.category}
                  showExpiredAt={!!offer.expired_at}
                  expiredAt={offer.expired_at}
                />
              )}
            </div>
          ))}
        </div>
        {showLoadMore && (
          <div className="list-offers__show-more">
            <ButtonLoadMore
              onClick={onClickButtonLoadMore}
              loading={loading}
              loadingMore={loadingMore}
            />
          </div>
        )}
      </div>
    );
  }
}

OfferDDMList.defaultProps = {
  items: [],
  loading: false,
  loadingMore: false,
  showLoadMore: false,
  channelUrl: '',
  brand: '',
  onClickButtonLoadMore: () => {},
};

OfferDDMList.propTypes = {
  items: PropTypes.arrayOf,
  showLoadMore: PropTypes.bool,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  channelUrl: PropTypes.string,
  brand: PropTypes.string,
  onClickButtonLoadMore: PropTypes.func,
};

export default OfferDDMList;
